import(/* webpackMode: "eager", webpackExports: ["BGGrid"] */ "/vercel/path0/components/bg-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommandMenu"] */ "/vercel/path0/components/command-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/components/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/progress-indicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/ClashDisplay-Bold.ttf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../public/fonts/ClashDisplay-Semibold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../public/fonts/ClashDisplay-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/ClashDisplay-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/ClashDisplay-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/ClashDisplay-Extralight.ttf\",\"weight\":\"200\",\"style\":\"normal\"}]}],\"variableName\":\"clashDisplay\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
